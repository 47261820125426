.react-contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: var(--color-white);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(146, 151, 183, 0.16);
  padding: 8px;
  min-width: 200px;
  z-index: 100;
  min-width: 261px;
  color: var(--color-grey-700);
  &__submenu--is-open,
  &__submenu--is-open > &__item__content {
    // color: white;
    background-color: var(--color-surface);
  }

  &__submenu--is-open > &__submenu {
    pointer-events: initial;
    opacity: 1;
  }

  & &__submenu {
    position: absolute;
    /* negate padding */
    top: -6px;
    pointer-events: none;
    transition: opacity 0.275s;
  }

  &__submenu-arrow {
    margin-left: auto;
    font-size: 12px;
  }

  &__separator {
    width: 100%;
    height: 1px;
    cursor: default;
    // margin: 4px 0;
    background-color: var(--color-grey-200);
  }

  &__will-leave--disabled {
    pointer-events: none;
  }

  &__item {
    cursor: pointer;
    position: relative;

    &:focus {
      outline: 0;
    }

    &:not(&--disabled):hover > &__content,
    &:not(&--disabled):focus > &__content {
      //   color: white;
      background-color: var(--color-surface);
    }

    &:not(&--disabled):hover > .react-contexify__submenu {
      pointer-events: initial;
      opacity: 1;
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;
    }
    &__content {
      padding: 12px;
      display: flex;
      gap: 0 8px;
      border-radius: 4px;
      align-items: center;
      white-space: nowrap;
      position: relative;
    }
  }
}
