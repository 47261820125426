@tailwind base;
@tailwind components;
@tailwind utilities;
@import './context-menu.scss';
/* @import '../'; */
@import '~react-toastify/dist/ReactToastify.css';

@font-face {
  font-family: 'defaultSatoshi';
  src: url('https://raw.githubusercontent.com/PrishaPolicy/public-assets/main/fonts/Satoshi-Regular.woff')
    format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'defaultSatoshi';
  src: url('https://raw.githubusercontent.com/PrishaPolicy/public-assets/main/fonts/Satoshi-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'defaultSatoshi';
  font-weight: 700;
  src: url('https://raw.githubusercontent.com/PrishaPolicy/public-assets/main/fonts/Satoshi-Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'defaultSatoshi';
  font-weight: 900;
  src: url('https://raw.githubusercontent.com/PrishaPolicy/public-assets/main/fonts/Satoshi-Black.woff') format('woff');
  font-display: swap;
}

* {
  font-family: defaultSatoshi, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}
.heading-1 {
  @apply text-title-1 font-bold;
}
.heading-2 {
  @apply text-title-2 font-bold;
}
.heading-3 {
  @apply text-title-3 font-bold;
}
.heading-4 {
  @apply text-title-4 font-bold;
}

.heading-5 {
  @apply text-title-5 font-bold;
}

.heading-6 {
  @apply text-title-6 font-bold;
}
.heading-7 {
  @apply text-title-7 font-bold;
}

.scrollable-style-1 {
  &::-webkit-scrollbar {
    @apply h-2;
    @apply w-2;
    @apply bg-grey-100;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-grey-200;
    @apply rounded-lg;
  }
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.container {
  max-width: calc(100% - 40px);
}
@screen sm {
  .container {
    max-width: min(100% - 40px, 640px);
  }
}

@screen xl {
  .container {
    max-width: min(100% - 60px, 1300px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
