@import '../../../libs/shared/styles/src/lib/base.scss';

.tawk-chatinput-send {
  color: #fff !important;
  > * {
    color: #fff !important;
  }
}

.tawk-chatinput-send-container {
  color: #fff !important;
  > * {
    color: #fff !important;
  }
}

.css-1h34uk6-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #4ec33d !important;
}
